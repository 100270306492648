import React from "react"

export default function Footer() {
  return (
    <div
      style={{
        backgroundColor: `#f26227`,
        textAlign: `center`,
      }}
    >
      <p
        style={{
          padding: `20px`,
          fontWeight: `600`,
          color: `#fff`,
          fontSize: `0.8rem`,
        }}
      >
        Made with ❤ by CORE
      </p>
      <p
        style={{
          padding: `45x`,
          fontWeight: `600`,
          color: `#f26227`,
          fontSize: `0.8rem`,
        }}
      >

      </p>
    </div>
  )
}
