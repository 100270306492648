import React from "react"
import Layout from "../components/layout.js"
import * as styles from "./members.module.css"
import data from "../../data/contact/contactData.json"
import Meta from "../components/meta.js"
import DPSMap from "../components/map.js"
import Footer from "../components/footer.js"
import Social from '../components/social'
export default function Contact() {
  return (
    <div>
      <Layout>
        <Meta />
        <h1>
          <mark
            style={{ fontWeight: `700`, fontSize: `3rem`, marginTop: `2rem` }}
          >
            Contact Us
          </mark>
        </h1>

        <div className={styles.mapsection} style={{}}>
          <DPSMap style={{ float: `right` }} />
          <p className={styles.contactdeets} style={{}}>
            Reach out to us at:
            <br /> <br />
            <h4 style={{ fontWeight: `600` }}>Delhi Public School, Dwarka</h4>
            <br />
            <p style={{ fontSize: `1.1rem`, marginTop: `5px` }}>
              <mark>
                <b>Address: </b>
              </mark>{" "}
              Phase I, Dwarka Sector-3, Dwarka, New Delhi, Delhi 110078
            </p>
            <p style={{ fontSize: `1.1rem`, marginTop: `10px` }}>
              <mark>
                <b>Phone: </b>
              </mark>{" "}
              011 2507 4472
            </p>
            <p style={{ fontSize: `1.1rem`, marginTop: `10px` }}>
              <mark>
                <b>Email: </b>
              </mark>{" "}
              mail@dpsdwarka.com
            </p>
            <br />
        <Social/> 
            <br />
            For any queries regarding CORE, feel free to contact any of the
            following:
          </p>
        </div>

        <div className={styles.container}>
          {data.map(person => (
            <div className={styles.card}>
              {/*<img className={styles.image} src={member.avatar} alt={member.name} />*/}
              <div className={styles.textContainer}>
                <h1 className={styles.name}>{person.name}</h1>
                <h2 className={styles.post}>{person.post}</h2>
                <p className={styles.desc}>
                  <span style={{ fontWeight: `600` }}>Phone: </span>
                  {person.phone}
                </p>
                <a className={styles.link} href={person.email}>
                  <mark>Email</mark>
                </a>
              </div>
            </div>
          ))}
        </div>
      </Layout>
      <Footer></Footer>
    </div>
  )
}
