import React from 'react'
import './social.css'
const social = () => {
    return (
        <>
            <div className="icons">
            <a href="https://www.instagram.com/coreisus" target="_blank"><i class="fab fa-instagram fa-2x connect" ></i></a>
            <a href="https://www.facebook.com/dpsCore/" target="_blank"><i class="fab fa-facebook connect" ></i></a>
            <a href="https://discord.gg/pmQxR2uagb" target="_blank"><i class="fab fa-discord connect" ></i></a>
            <a href="mailto:hailcoreisus@gmail.com" target="_blank"><i class="fas fa-envelope connect" ></i></a>
            </div>
        </>
    )
}

export default social
