// extracted by mini-css-extract-plugin
export var YearContainer = "members-module--YearContainer--e97a8";
export var card = "members-module--card--d7c65";
export var cont = "members-module--cont--a6f95";
export var contactdeets = "members-module--contactdeets--4f68a";
export var container = "members-module--container--f8d44";
export var desc = "members-module--desc--b93a1";
export var gmap_canvas = "members-module--gmap_canvas--473c0";
export var image = "members-module--image--e6089";
export var link = "members-module--link--0f849";
export var mapouter = "members-module--mapouter--75fba";
export var mapsection = "members-module--mapsection--efbd0";
export var name = "members-module--name--db18d";
export var post = "members-module--post--4b15c";
export var socialbtns = "members-module--socialbtns--b4d07";
export var socialbutton = "members-module--socialbutton--25617";
export var socials = "members-module--socials--8fe38";
export var textContainer = "members-module--textContainer--0fc19";